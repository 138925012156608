import React from "react"
import { navigate } from "gatsby"

import Page from "../../plugins/gatsby-grapesjs-page-builder/src/templates/page"
import {
  sessionStore,
  localStore,
} from "../../plugins/gatsby-grapesjs-page-builder/src/utils/customStorage"

const titres = {
  "1": "Conditions d'utilisation",
  "1-1": "Éléments signalétiques",
  "1-2": "Personne répondante",
  "1-3": "Personne visée par vos observations",
  "1-4": "Type d'organisation pour laquelle vous travaillez",
  "2": "Questions reliées aux signes avant-coureurs de la maladie",
  "2-1": "Altérations du fonctionnement cognitif",
  "2-2": "Questions reliées aux signes avant-coureurs de la maladie",
  "2-3": "Altérations de l'humeur",
  "2-4": "Altérations du comportement",
  "2-5": "Altérations au niveau des pensées",
  "3": "Questions complémentaires",
  "3-1": "Enfant différent",
  "3-2": "Ressenti",
  "4": "Questions reliées aux facteurs de risque",
  "4-1": "Antécédents familiaux",
  "4-2": "Drogues et autres dépendances",
  "5": "Autres facteurs de risque",
  "5-1": "Problématique de santé mentale",
  "5-2": "Stresseurs",
  "5-3": "Risques périnataux",
  "5-4":
    "Risques liés à l'environnement de la personne au moment de la naissance",
}

const steps = Object.keys(titres).sort((a, b) =>
  parseFloat(a.replace("-", ".")) < parseFloat(b.replace("-", ".")) ? -1 : 1
)

const findStep = (step, index, type) => {
  let result = {
    nextStep: steps[index + 1],
    prevStep: steps[index - 1],
  }

  if (step === "1-3" && type !== "professionnels")
    result.nextStep = steps[index + 2]
  else if (step === "2" && type !== "professionnels")
    result.prevStep = steps[index - 2]

  if (step === "2-5" && type === "professionnels") result.nextStep = "4"
  else if (step === "4" && type === "professionnels") result.prevStep = "2-5"

  return result
}

const PageTemplate = props => {
  const {
    location: { pathname },
  } = props

  const params = pathname.split("/")

  const step = params[4]
  const stepSection = !params[4] || step === "1" ? "0" : params[4].split("-")[0]
  const index = steps.findIndex(s => s === step)

  let pageData = {
    type: params[3],
    step: step,
    titres,
    stepSection,
    isFinalStep: (params[3] !== "famille" && step == "5-2") || step == "5-4",
    urlLang: params[1],
    ...findStep(step, index, params[3]),
  }

  console.log("SESSION STORAGE", sessionStore.getItem("pb-forms"))
  const formData = sessionStore.getItem("pb-forms")
    ? JSON.parse(sessionStore.getItem("pb-forms"))
    : {}

  if (
    step !== "1" &&
    !props.canvas &&
    typeof window !== "undefined" &&
    (!formData.questionnaire ||
      !formData.questionnaire.values ||
      !formData.questionnaire.values["0.0.terms"])
  ) {
    navigate(`/${params[1]}/questionnaire/${params[3]}/1`)
  }

  return <Page pageData={pageData} {...props} />
}

export default PageTemplate
